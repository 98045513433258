import { RecordSelect } from '@smartplatform/consta/ui/RecordSelect';
import { renderLabel } from './renderLabel';
import React from 'react';
import classnames from 'classnames';
import store from '@appStore';

export class LabelSelect extends React.PureComponent {
	constructor(props) {
		super(props);
		const size = this.props.size;
		if (props.getItemLabel) {
			this.getItemLabel = (item) => getItemLabel(item, size);
		} else {
			this.getItemLabel = (item) => renderLabel(item, size);
		}

		if (props.renderValue) {
			this.renderValue = props.renderValue(item, size);
		} else {
			this.renderValue = (item) => renderLabel(item, size);
		}
	}
	render() {
		let { size = 'm', className, ...restProps } = this.props;
		className = classnames('LabelSelect', className);

		return (
			<RecordSelect
				{...restProps}
				size={size}
				className={className}
				renderValue={this.renderValue}
				getItemLabel={this.getItemLabel}
				model={store.model.Label}
			/>
		);
	}
}
