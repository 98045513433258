export default {
	KanbanCard: {
		count: 'Количество',
		sum: 'Сумма',
		positionCount: 'Позиций',
		showAll: 'Показать все',
		showLess: 'Скрыть',
		overdue: 'Истек',
	},
};

