import React from 'react';
import t from 'i18n';
import { ActionbarContext } from './Actionbar';
import { Button } from '@consta/uikit/Button';

export const SaveButton = () => {
	const { form, record, saveTitle, noSave, disableSave } = React.useContext(ActionbarContext);

	if (noSave) return null;

	const onSave = async () => await form.save();

	const defaultTitle = record.id ? t('save') : t('create');

	return <Button onClick={onSave} disabled={disableSave} label={saveTitle || defaultTitle} />;
};

