import React from 'react';
import { ActionbarContext } from './Actionbar';
import { DeleteConfirmButton } from '@features/DeleteConfirmButton';
import t from 'i18n';

export const DeleteButton = () => {
	const { form, deleteTitle, noDelete, disableDelete, record } = React.useContext(ActionbarContext);

	if (noDelete || !record.id) return null;
	const onDelete = async () => await form.delete();
	const defaultTitle = t('delete');

	return <DeleteConfirmButton onConfirm={onDelete} label={deleteTitle || defaultTitle} disabled={disableDelete} />;
};
